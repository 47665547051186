'use client';

import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import Loader from 'nextjs-toploader';
import NProgress from 'nprogress';
import { useTheme } from 'styled-components';

/**
 * The component is used to display a toploader on page navigation
 */
export const TopLoader: React.FC = () => {
  const pathname = usePathname();

  const { zIndexes, colors } = useTheme();

  useEffect(() => {
    NProgress.done();
  }, [pathname]);

  return <Loader zIndex={zIndexes.sticky} color={colors.text.link} showSpinner={false} />;
};
