/**
 * The datadog initialisation always has to happen on the client.
 * To make sure that happens we mark the component as a client component even though it might not be needed
 */
'use client';

import { datadogRum } from '@datadog/browser-rum-slim';
import { useEffect, useMemo } from 'react';

import { COOKIE_CONSENT, DATADOG_CONFIG, TRACKING_CONSENT } from '@/config';
import { useCookieConsent } from '@/hooks';

interface Props {
  /**
   * The current environment. Staging | Production
   */
  environment: string;
}

/**
 * The component takes care of initialising Datadog RUM
 */
export const DatadogInit: React.FC<Props> = (props) => {
  const { environment } = props;

  const statisticsConsent = useCookieConsent(COOKIE_CONSENT.STATISTIC);

  //Memoize the enabled state. Without the memoization, we would create too many recordings in Clarity
  const enabled = useMemo(() => ['staging', 'production'].includes(environment), [environment]);

  /**
   * set datadog permission to monitor depending on users cookie consent
   * user has to give statistics consent
   */
  useEffect(() => {
    if (!enabled) return;

    const trackingConsent = statisticsConsent ? TRACKING_CONSENT.GRANTED : TRACKING_CONSENT.NOT_GRANTED;

    datadogRum.setTrackingConsent(trackingConsent);
  }, [enabled, statisticsConsent]);

  /**
   * initialize datadog monitoring
   */
  useEffect(() => {
    if (!enabled) return;

    /**
     * getInternalContext() checks if initial connection has been established already
     */
    if (!datadogRum.getInternalContext()) {
      /**
       * Initialises datadog RUM (Real User Monitoring)
       * @see https://docs.datadoghq.com/real_user_monitoring/guide/monitor-your-nextjs-app-with-rum/?tab=npm
       */
      datadogRum.init(DATADOG_CONFIG(environment));
    }
  }, [enabled, environment]);

  // render nothing - this component is only included so that the init code above will run client-side
  return null;
};

export default DatadogInit;
