'use client';

import { useEffect, useMemo } from 'react';
import Clarity from '@microsoft/clarity';

import { useCookieConsent } from '@/hooks';
import { COOKIE_CONSENT } from '@/config';

interface Props {
  /**
   * The current environment. Staging | Production
   */
  environment: string;
}

/**
 * This component initializes the Clarity analytics script on the client.
 */
export const ClarityInit: React.FC<Props> = (props) => {
  const { environment } = props;

  const CLARITY_PROJECT_ID = process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID!;

  const hasStatisticCookieConsent = useCookieConsent(COOKIE_CONSENT.STATISTIC);

  //Memoize the enabled state. Without the memoization, we would create too many recordings in Clarity
  const enabled = useMemo(() => ['staging', 'production'].includes(environment), [environment]);

  useEffect(() => {
    // only run if enabled
    if (!enabled) return;

    /**
     * Initialize Clarity if the script is not already loaded
     * If the script is loaded, we can call the consent function
     */
    if (!window.clarity) {
      Clarity.init(CLARITY_PROJECT_ID);
    } else {
      Clarity.consent(hasStatisticCookieConsent);
    }
  }, [enabled, CLARITY_PROJECT_ID, hasStatisticCookieConsent]);

  return null;
};
