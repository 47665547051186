'use client';

import { ThemeProvider, ViewportProvider } from '@whiteaway/ui';

import { CookieConsentsProvider, TranslationsProvider } from '@/contexts';

interface Props extends React.PropsWithChildren {}

export const Providers: React.FC<Props> = (props) => {
  const { children } = props;

  return (
    <ThemeProvider theme="hvidevaregrossisten">
      <CookieConsentsProvider>
        <TranslationsProvider>
          <ViewportProvider>{children}</ViewportProvider>
        </TranslationsProvider>
      </CookieConsentsProvider>
    </ThemeProvider>
  );
};

export default Providers;
