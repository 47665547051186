'use client';

import React, { useEffect, useState } from 'react';
import { useServerInsertedHTML } from 'next/navigation';
import { ServerStyleSheet, StyleSheetManager } from 'styled-components';

export default function StyledComponentsRegistry({ children }: { children: React.ReactNode }) {
  // Only create stylesheet once with lazy initial state
  // x-ref: https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
  const [styledComponentsStyleSheet] = useState(() => new ServerStyleSheet());

  useServerInsertedHTML(() => {
    const styles = styledComponentsStyleSheet.getStyleElement();
    styledComponentsStyleSheet.instance.clearTag();
    return <>{styles}</>;
  });

  // When we are streaming content, the temporary styles insterted by styled-components,
  // are not removed from the DOM. This is a workaround to remove them.
  //
  // See: https://nextjs.org/docs/app/building-your-application/styling/css-in-js#styled-components
  // Good to know:
  //  - During streaming, styles from each chunk will be collected and appended to existing styles.
  //    After client-side hydration is complete, styled-components will take over as usual and inject any further dynamic styles.
  useEffect(() => {
    const resetTemporarySSRStyling = () => {
      document.querySelectorAll('body style[data-styled]').forEach((element) => {
        if (element.getAttribute('data-styled') === 'ACTIVE') return;
        element.remove();
      });
    };

    window.addEventListener('load', resetTemporarySSRStyling);
    return () => {
      window.removeEventListener('load', resetTemporarySSRStyling);
    };
  }, []);

  if (typeof window !== 'undefined') return <>{children}</>;

  return <StyleSheetManager sheet={styledComponentsStyleSheet.instance}>{children}</StyleSheetManager>;
}
