import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/circleci/project/app/datadog-init.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/circleci/project/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/circleci/project/app/registry.tsx");
;
import(/* webpackMode: "eager" */ "/home/circleci/project/components/top-loader/top-loader.tsx");
;
import(/* webpackMode: "eager" */ "/home/circleci/project/contexts/cart-context/cart-context.tsx");
;
import(/* webpackMode: "eager" */ "/home/circleci/project/contexts/error-context/error-context.tsx");
;
import(/* webpackMode: "eager" */ "/home/circleci/project/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/circleci/project/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/circleci/project/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["SnackbarProvider"] */ "/home/circleci/project/node_modules/@whiteaway/ui/dist/src/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/home/circleci/project/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/home/circleci/project/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/circleci/project/utils/clarity/clarity-init.tsx");
