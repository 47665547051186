import { useContext } from 'react';

import { CookieConsent, CookieConsentContext } from '@/contexts';

/**
 * Custom hook to check if a specific cookie consent is present in the current context.
 * @param consent The consent to check
 * @returns
 */
export const useCookieConsent = (consent: CookieConsent): boolean => {
  const cookieConsents = useContext(CookieConsentContext);

  return cookieConsents.includes(consent);
};
